import { ChangeEvent, MouseEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import {
  getOnHoldAssessmentRequests,
  setPageNumber,
  setPageSize,
  setSearchText,
  resetLoadingStatus,
  setShowAssignedToCurrentUser,
  DEFAULT_PAGE_SIZE,
  OnHoldAssessmentRequestsItem
} from './get-on-hold-assessment-requests-slice'
import AnalystUsersList from '../analyst/analyst-users-list'
import { getAnalystUsers } from '../get-analyst-users-slice'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import TabPanel from '../../../components/tab-panel'
import { formatDate, formatDuration, isSuperAnalyst, hasAnalystRole, isAnalystRoleOnly, isAdmin, secureFileDownload } from '../../../utils/functions'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { usePermission } from '../../../hooks/use-permission'
import { useRole } from '../../../hooks/use-role'
import { CustomerSuperformContextMenuItem, EntitySuperformContextMenuItem, ExpiredContextMenuItem, InternalNoteContextMenuItem, ReassignAnalystContextMenuItem, UnOnHoldAssessmentsContextMenuItem } from '../components/assessments-action-menu'
import { InternalNotes } from '../../../components/internal-notes'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../components/expire-assessment-confirmation-dialog'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import { AssessmentRemoveOnHoldConfirmationDialog, AssessmentRemoveOnHoldConfirmationDialogState } from '../components/assessment-remove-on-hold-confirmation-dialog'
import { ActionMenu } from '../../../components/action-menu'
import { AssessmentFooter } from '../components/assessment-footer'
import { TableHeader } from '../../../components/table-header'
import { formatCustomerName } from '../../../components/customer-name-component'
import { useEventListener } from '../../../hooks/use-event-listener'

const OnHoldAssessmentTabs = {
  All: 'all',
  AssignedToCurrentUser: 'assignedToCurrentUser'
}

export const columns: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 220,
    className: 'wrapped-text'
  },
  {
    id: 'orderedDate',
    label: 'Date Ordered',
    minWidth: 120,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customerName',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text',
    format: formatCustomerName
  },
  {
    id: 'entityAssessed',
    label: 'Entity Assessed',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'onHoldReason',
    label: 'On Hold Reason',
    minWidth: 150
  },
  {
    id: 'timeElapsedSinceOrdering',
    label: 'Time Elapsed Since Client Ordering',
    minWidth: 150
  },
  {
    id: 'entityAssessedResponseTime',
    label: 'Time Elapsed Since Entity Assessed Responded',
    minWidth: 190
  }
]

const OnHoldAssessments = () => {
  const dispatch = useAppDispatch()

  const roles = useRole()
  const isAnalystRole = isAnalystRoleOnly(roles)

  const defaultTab = useMemo(() => { return isAnalystRole ? OnHoldAssessmentTabs.AssignedToCurrentUser : OnHoldAssessmentTabs.All }, [isAnalystRole])

  const {
    getOnHoldAssessmentState, getAnalystUsersState, assignAnalystUserToAssessmentRequestsState,
    updateExpiredAssessmentState, triggerManualCreatedEventState,
    tokenState
  } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount } =
    getOnHoldAssessmentState

  const [writeAssignment, writeInternalNotes, writeAssessment] = usePermission([AnilityScopes.Write.Assignment, AnilityScopes.Write.InternalNotes, AnilityScopes.Write.Assessment])

  const [tabSearchValues, setTabSearchValues] = useState<Record<string, string>>({ all: '', assignedToCurrentUser: '' })
  const [tabPageNumberValues, setTabPageNumberValues] = useState<Record<string, number>>({ all: 1, assignedToCurrentUser: 1 })

  const [selectedTab, setSelectedTab] = useState<string>(defaultTab)
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showRemoveOnHoldAssessmentDialog, setShowRemoveOnHoldAssessmentDialog] = useState<AssessmentRemoveOnHoldConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined,
      status: ''
    }
  )

  const loading = getOnHoldAssessmentState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' || getAnalystUsersState.loading === 'loading' ||
    updateExpiredAssessmentState.loading === 'loading' || triggerManualCreatedEventState.loading === 'loading'

  const resetTabLoadingStates = () => {
    dispatch(resetLoadingStatus(false))
  }

  useEffect(() => {
    dispatch(setSearchText(''))
    dispatch(setShowAssignedToCurrentUser(defaultTab === OnHoldAssessmentTabs.AssignedToCurrentUser))
    dispatch(setPageNumber(1))
    dispatch(setPageSize(DEFAULT_PAGE_SIZE))
  }, [defaultTab])

  useEffect(() => {
    dispatch(resetLoadingStatus(true))
  }, [])

  useEffect(() => {
    getOnHoldAssessmentState.loading === 'idle' &&
      dispatch(getOnHoldAssessmentRequests({}))
  }, [dispatch, getOnHoldAssessmentState.loading])

  useEffect(() => {
    if (getAnalystUsersState.loading === 'idle') {
      dispatch(getAnalystUsers())
    }
  }, [getAnalystUsersState.loading])

  const handleTabChange = (event: SyntheticEvent, tabValue: string) => {
    setSelectedTab(tabValue)
    dispatch(setShowAssignedToCurrentUser(tabValue === OnHoldAssessmentTabs.AssignedToCurrentUser))

    dispatch(setSearchText(tabSearchValues[tabValue]))
    dispatch(setPageNumber(tabPageNumberValues[tabValue]))

    resetTabLoadingStates()
  }
  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    const currentPageNumber = selectedPage + 1
    if (selectedTab === OnHoldAssessmentTabs.All) {
      setTabPageNumberValues({ ...tabPageNumberValues, all: currentPageNumber })
    }
    if (selectedTab === OnHoldAssessmentTabs.AssignedToCurrentUser) {
      setTabPageNumberValues({ ...tabPageNumberValues, assignedToCurrentUser: currentPageNumber })
    }
    dispatch(setPageNumber(currentPageNumber))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleFVASearchTextOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    selectedTab === OnHoldAssessmentTabs.All && setTabSearchValues({ ...tabSearchValues, all: event.target.value })
    selectedTab === OnHoldAssessmentTabs.AssignedToCurrentUser && setTabSearchValues({ ...tabSearchValues, assignedToCurrentUser: event.target.value })
  }

  const handleFVAResetSearch = () => {
    selectedTab === OnHoldAssessmentTabs.All && setTabSearchValues({ ...tabSearchValues, all: '' })
    selectedTab === OnHoldAssessmentTabs.AssignedToCurrentUser && setTabSearchValues({ ...tabSearchValues, assignedToCurrentUser: '' })

    dispatch(setSearchText(''))
  }

  const handleFVASearch = () => {
    dispatch(setSearchText(tabSearchValues[selectedTab]))
    selectedTab === OnHoldAssessmentTabs.All && setTabPageNumberValues({ ...tabPageNumberValues, all: 1 })
    selectedTab === OnHoldAssessmentTabs.AssignedToCurrentUser && setTabPageNumberValues({ ...tabPageNumberValues, assignedToCurrentUser: 1 })
  }

  const getTabRoles = () => {
    if ((isAdmin(roles) || isSuperAnalyst(roles)) && hasAnalystRole(roles)) {
      return [{ id: OnHoldAssessmentTabs.All, label: 'All' },
        { id: OnHoldAssessmentTabs.AssignedToCurrentUser, label: 'Assigned To Me' }
      ]
    }
    if ((isAdmin(roles) || isSuperAnalyst(roles)) && !hasAnalystRole(roles)) {
      return [{ id: OnHoldAssessmentTabs.All, label: 'All' }
      ]
    }
    if (isAnalystRoleOnly(roles)) {
      return [{ id: OnHoldAssessmentTabs.AssignedToCurrentUser, label: 'Assigned To Me' }]
    }
    return []
  }

  const renderDynamicTabs = () => {
    const tabs: Record<string, string>[] = getTabRoles()

    return tabs.map(({ id, label }) =>
      (
      <Tab
        key={id}
        value={id}
        label={label}
        id={`tab-${id}`}
        aria-controls={`tabpanel-${id}`}
        sx={{ textTransform: 'none', width: 200 }}
      />
      ))
  }

  const handleInternalNotesClick = (item: OnHoldAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const handleExpireAssessmentSubmitSuccess = () => {
    dispatch(getOnHoldAssessmentRequests({}))
    setAssignmentEnabledId(null)
  }

  const handleAssessmentRemoveOnHoldSubmitSuccess = () => {
    dispatch(getOnHoldAssessmentRequests({}))
  }

  const generateContextMenu = (item: OnHoldAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      CustomerSuperformContextMenuItem({
        onClick: () => {
          window.open(`${item.requestLink}?source=on_hold`, '_blank')?.focus()
        },
        show: !!item.requestLink
      }),
      EntitySuperformContextMenuItem({
        onClick: () => {
          window.open(`${item.responseLink}?source=on_hold`, '_blank')?.focus()
        },
        show: !!item.responseLink
      }),
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      UnOnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => setShowRemoveOnHoldAssessmentDialog({
          show: true,
          assessmentRequestId: item.id,
          status: item.status
        })
      })
    ]
  }

  const formattedOnHoldAssessmentList = items.map((item, index) => {
    return {
      ...item,
      orderedDate: formatDate(item.orderDate),
      customerName: item.customer,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      entityAssessedResponseTime: item.entityAssessedResponseTime === 0 ? '' : formatDuration(item.entityAssessedResponseTime),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          value={item.assignedAnalyst!}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          onSuccess={() => {
            resetTabLoadingStates()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label
    }
  })
  const page = { totalCount, size: pageSize, number: pageNumber }

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('search', tabSearchValues[selectedTab])
    params.append('isOnHold', 'true')

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  useEventListener('message', (e: MessageEvent) => {
    const clientOrigin = items.length > 0 ? items[0].requestLink : null
    if (clientOrigin?.includes(e.origin) && e.data.methodToCall === 'reloadOnHoldAssessments') {
      dispatch(getOnHoldAssessmentRequests({}))
    }
  })

  return (
    <Box p={2} sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative'
    }}>
      <Stack spacing={1.5} sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Typography variant='h3'>On Hold</Typography>
        <Typography color={({ palette }) => palette.text.secondary}>Assessments that are on hold</Typography>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="on-hold-assessment-tabs">
          {renderDynamicTabs()}
        </Tabs>
      </Box>
      {getTabRoles().map((item) => (
        <TabPanel key={`on-hold-assessment-${item.id}`} id={`on-hold-assessment-${item.id}`} selectedTab={selectedTab} value={item.id}>
          <PaginatedTable
            columns={columns}
            items={formattedOnHoldAssessmentList}
            headerComponent={
              <TableHeader
                searchValue={tabSearchValues[selectedTab]}
                onSearchTextChange={handleFVASearchTextOnChange}
                onSearchClick={handleFVASearch}
                onResetSearchClick={handleFVAResetSearch}
                onExportButtonClick={handleExportButtonClick}
              />
            }
            footerComponent={
              <AssessmentFooter
                page={page}
                onTablePageChange={handlePageChange}
                onTablePageSizeChange={handlePageSizeChange}
              />
            }
            renderMenuRowActions={(item, index, itemLength) => (
              <ActionMenu
                isContextMenuDisabled={false}
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)}
                index={index}
                itemLength={itemLength}
              />
            )}
          />
        </TabPanel>
      ))}
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <AssessmentRemoveOnHoldConfirmationDialog
        open={showRemoveOnHoldAssessmentDialog.show}
        assessmentRequestId={showRemoveOnHoldAssessmentDialog.assessmentRequestId}
        status={showRemoveOnHoldAssessmentDialog.status}
        onSubmitSuccess={handleAssessmentRemoveOnHoldSubmitSuccess}
        onClose={() => setShowRemoveOnHoldAssessmentDialog({
          show: false,
          assessmentRequestId: undefined,
          status: ''
        })} />
      <AnilityBackdrop open={loading} />
    </Box>
  )
}

export default OnHoldAssessments
