import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { OrderedAssessmentRequestsItem, getOrderedAssessmentRequests, setPageNumber, setPageSize, setSearchText, resetLoadingStatus } from './get-ordered-assessment-requests-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { formatDate, formatDuration, secureFileDownload } from '../../../utils/functions'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { Box } from '@mui/material'
import { ExpiredContextMenuItem, InternalNoteContextMenuItem, OnHoldAssessmentsContextMenuItem, ReassignAnalystContextMenuItem, TriggerCreateEventContextMenuItem } from '../components/assessments-action-menu'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { InternalNotes } from '../../../components/internal-notes'
import { setSelectedAssessmentInternalNote } from '../../../components/set-internal-notes-slice'
import { usePermission } from '../../../hooks/use-permission'
import { useEventListener } from '../../../hooks/use-event-listener'
import AnalystUsersList from '../analyst/analyst-users-list'
import { getAnalystUsers } from '../get-analyst-users-slice'
import { ExpireAssessmentConfirmationDialog, ExpireAssessmentConfirmationDialogState } from '../components/expire-assessment-confirmation-dialog'
import { TriggerManualCreatedEventConfirmationDialog, TriggerManualCreatedEventConfirmationDialogState } from '../components/trigger-manual-created-event-confirmation-dialog'
import AssessmentOnHoldDialogForm, { AssessmentOnHoldDialogState } from '../components/assessment-on-hold-dialog-form'
import { ActionMenu } from '../../../components/action-menu'
import { AssessmentFooter } from '../components/assessment-footer'
import { TableHeader } from '../../../components/table-header'
import { formatCustomerName } from '../../../components/customer-name-component'

export const columns: Column[] = [
  {
    id: 'assignedAnalyst',
    label: 'Assigned To',
    minWidth: 220,
    className: 'wrapped-text'
  },
  {
    id: 'orderDate',
    label: 'Date Ordered',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'reportConfigurationId',
    label: 'Assessment Type',
    minWidth: 90
  },
  {
    id: 'customer',
    label: 'Customer Name',
    minWidth: 150,
    className: 'wrapped-text',
    format: formatCustomerName
  },
  {
    id: 'entityAssessed',
    label: 'Entity Assessed Name',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'contractName',
    label: 'Assessment Subject',
    minWidth: 150,
    className: 'wrapped-text'
  },
  {
    id: 'nominatedContactName',
    label: 'Contact Name',
    minWidth: 125,
    className: 'wrapped-text'
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 125,
    className: 'wrapped-text'
  },
  {
    id: 'nominatedUserEmailAddress',
    label: 'Email Address',
    minWidth: 175,
    className: 'wrapped-text'
  },
  {
    id: 'timeElapsedSinceOrdering',
    label: 'Time Elapsed Since Ordering',
    minWidth: 150,
    className: 'wrapped-text'
  }
]

const OrderedAssessments = () => {
  const dispatch = useAppDispatch()
  const {
    getOrderedAssessmentRequestsState, updateExpiredAssessmentState, getAnalystUsersState, assignAnalystUserToAssessmentRequestsState, triggerManualCreatedEventState, tokenState
  } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getOrderedAssessmentRequestsState
  const loading = getOrderedAssessmentRequestsState.loading === 'loading' || updateExpiredAssessmentState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' || getAnalystUsersState.loading === 'loading' ||
    triggerManualCreatedEventState.loading === 'loading'

  const [localSearch, setLocalSearch] = useState(searchText)
  const [showInternalNotes, setShowInternalNotes] = useState(false)
  const [writeInternalNotes, writeAssignment, writeAssessment] = usePermission([AnilityScopes.Write.InternalNotes,
    AnilityScopes.Write.Assignment, AnilityScopes.Write.Assessment])

  const [assignmentEnabledId, setAssignmentEnabledId] = useState<number | null>(null)
  const [showExpireAssessmentConfirmationDialog, setShowExpireAssessmentConfirmationDialog] = useState<ExpireAssessmentConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showTriggerManualCreatedEventConfirmationDialog, setShowTriggerManualCreatedEventConfirmationDialog] = useState<TriggerManualCreatedEventConfirmationDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )
  const [showAssessmentOnHoldDialogForm, setShowAssessmentOnHoldDialogForm] = useState<AssessmentOnHoldDialogState>(
    {
      show: false,
      assessmentRequestId: undefined
    }
  )

  const handleExpireAssessmentSubmitSuccess = () => {
    loadOrderedAssessments()
    setAssignmentEnabledId(null)
  }

  const handleAssessmentOnHoldSubmitSuccess = () => {
    dispatch(resetLoadingStatus(false))
  }

  const handleInternalNotesClick = (item: OrderedAssessmentRequestsItem) => {
    setShowInternalNotes(true)
    dispatch(
      setSelectedAssessmentInternalNote(item.id)
    )
  }

  const generateContextMenu = (item: OrderedAssessmentRequestsItem): MenuListItemProps[] => {
    return [
      ExpiredContextMenuItem({
        onClick: () => setShowExpireAssessmentConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      }),
      InternalNoteContextMenuItem({
        onClick: () => handleInternalNotesClick(item)
      }),
      ReassignAnalystContextMenuItem({
        onClick: () => { setAssignmentEnabledId(item.id) }
      }),
      OnHoldAssessmentsContextMenuItem({
        show: item.hasAssignedAnalyst && writeAssessment,
        onClick: () => {
          setShowAssessmentOnHoldDialogForm({
            show: true,
            assessmentRequestId: item.id
          })
        }
      }),
      TriggerCreateEventContextMenuItem({
        onClick: () => setShowTriggerManualCreatedEventConfirmationDialog({
          show: true,
          assessmentRequestId: item.id
        })
      })
    ]
  }

  const loadOrderedAssessments = () => {
    dispatch(getOrderedAssessmentRequests({
      status: 'CollectingRequiredInformation'
    }))
  }

  useEffect(() => {
    setLocalSearch('')
    dispatch(resetLoadingStatus(true))
  }, [])

  useEffect(() => {
    if (getOrderedAssessmentRequestsState.loading === 'idle') {
      loadOrderedAssessments()
    }
  }, [getOrderedAssessmentRequestsState.loading])

  useEffect(() => {
    if (getAnalystUsersState.loading === 'idle') {
      dispatch(getAnalystUsers())
    }
  }, [getAnalystUsersState.loading])

  const handleReloadAssessments = (event: MessageEvent) => {
    if (event.origin === process.env.REACT_APP_API_BASE && event.data.methodToCall === 'reloadOrderedAssessments') {
      loadOrderedAssessments()
    }
  }
  useEventListener('message', handleReloadAssessments)

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleViewCustomerSuperform = (item: OrderedAssessmentRequestsItem) => {
    if (!item.requestLink) return
    window.open(`${item.requestLink}?source=ordered`, 'blank')
  }

  const formattedColumns = columns.map((item) => {
    return {
      ...item,
      onRowClick: item.id === 'assignedAnalyst' && writeAssignment ? () => { } : undefined
    }
  })

  const formattedOrderedAssessments = items.map((item, index) => {
    return {
      ...item,
      orderDate: formatDate(item.orderDate),
      hasAssignedAnalyst: !!item.assignedAnalyst?.label,
      assignedAnalyst: writeAssignment
        ? <AnalystUsersList
          options={getAnalystUsersState.items}
          id={item.id}
          index={index}
          disabled={!!item.assignedAnalyst?.value && item.id !== assignmentEnabledId}
          value={item.assignedAnalyst!}
          onSuccess={() => {
            loadOrderedAssessments()
            setAssignmentEnabledId(null)
          }}
        />
        : item.assignedAnalyst?.label,
      timeElapsedSinceOrdering: formatDuration(item.timeElapsedSinceOrdering),
      rowInlineStyles: { cursor: 'pointer' }
    }
  })

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()

    params.append('status', 'CollectingRequiredInformation')
    params.append('search', searchText)

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/assessmentRequests/export?${params.toString()}`, tokenState.token)
  }

  const page = { totalCount, size: pageSize, number: pageNumber }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <PaginatedTable
          columns={formattedColumns}
          items={formattedOrderedAssessments}
          headerComponent={
            <TableHeader
              title="Ordered"
              description="Assessments that have been ordered, however are waiting for the entity assessed to respond"
              searchValue={localSearch}
              onSearchTextChange={handleSearchTextChange}
              onSearchClick={handleSearch}
              onResetSearchClick={handleResetSearch}
              onExportButtonClick={handleExportButtonClick} />}
          footerComponent={
            <AssessmentFooter
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />
          }
          renderMenuRowActions={(item, index, itemLength) => {
            return (
              <ActionMenu
                isContextMenuDisabled={false}
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)} index={index} itemLength={itemLength} />
            )
          }}
          onRowClick={handleViewCustomerSuperform}
        />
      </Box>
      {writeInternalNotes && <InternalNotes open={showInternalNotes} handleClose={() => setShowInternalNotes(false)} />}
      <ExpireAssessmentConfirmationDialog
        open={showExpireAssessmentConfirmationDialog.show}
        assessmentRequestId={showExpireAssessmentConfirmationDialog.assessmentRequestId}
        onClose={() => setShowExpireAssessmentConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
        onSubmitSuccess={handleExpireAssessmentSubmitSuccess}
      />
      <TriggerManualCreatedEventConfirmationDialog
        open={showTriggerManualCreatedEventConfirmationDialog.show}
        assessmentRequestId={showTriggerManualCreatedEventConfirmationDialog.assessmentRequestId}
        onClose={() => setShowTriggerManualCreatedEventConfirmationDialog({
          show: false,
          assessmentRequestId: undefined
        })}
      />
      <AssessmentOnHoldDialogForm
        show={showAssessmentOnHoldDialogForm.show}
        assessmentRequestId={showAssessmentOnHoldDialogForm.assessmentRequestId}
        onSubmitSuccess={handleAssessmentOnHoldSubmitSuccess}
        onClose={() =>
          setShowAssessmentOnHoldDialogForm({
            show: false,
            assessmentRequestId: undefined
          })} />
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default OrderedAssessments
