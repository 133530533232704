import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import axios, { AxiosError } from 'axios'
import { getGenericErrorMessage } from '../../../utils/functions'

interface UpdateCustomerIdentifierState {
  loading: LoadingType
  error: string
}

const initialState: UpdateCustomerIdentifierState = {
  loading: 'idle',
  error: ''
}

interface UpdateCustomerIdentifierParams {
  customerId: number
  customerIdentifier: string
  onSuccess?: () => void
}

export const updateCustomerIdentifier = createAsyncThunk<
    void,
    UpdateCustomerIdentifierParams,
    {
        dispatch: AppDispatch
        state: RootState,
        rejectValue: RejectValue
    }>('customer/updateCustomerIdentifier',
      async (params, thunkAPI) => {
        const { tokenState, appSettingsState } = thunkAPI.getState()

        try {
          await axios.patch(
                `${process.env.REACT_APP_API_BASE}/ui/admin/customers/${params.customerId}/customer-identifier`,
                {
                  customerId: params.customerId,
                  customerIdentifier: params.customerIdentifier
                },
                {
                  validateStatus: (status) => status < 400,
                  headers: { Authorization: `Bearer ${tokenState.token}` }
                })
          params.onSuccess?.()
        } catch (error) {
          const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
          if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
            return thunkAPI.rejectWithValue({ message: errors.response.data.title })
          }

          return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
        }
      })

export const updateCustomerIdentifierSlice = createSlice({
  name: 'update-customer-identifier',
  initialState,
  reducers: {
    resetUpdateCustomerIdentifier: (state) => {
      state.loading = 'idle'
      state.error = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomerIdentifier.pending, (state) => {
      state.loading = 'loading'
      state.error = ''
    })
    builder.addCase(updateCustomerIdentifier.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(updateCustomerIdentifier.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { resetUpdateCustomerIdentifier } = updateCustomerIdentifierSlice.actions
export default updateCustomerIdentifierSlice.reducer
