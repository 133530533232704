import { Menu, MenuProps } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { createTheme, styled, alpha } from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'

export const Colors = {
  mainColor: '#262626',
  secondaryColor: '#DBE0E7',
  backgroundColor: '#f1eff4',
  primaryText: '#202124',
  secondaryText: '#808080',
  paragraphColor: '#4E5A74',
  // hover appearance done by changing the opacity to 0.9
  hoverColor: '#262626',
  white: '#FFFFFF',
  tableHeaderBackground: '#F7F7F7',
  tableHeaderText: '#979797',
  avatarColor: '#363636',
  checkIconColor: '#20C26B',
  closeIconColor: '#FF0B0B',
  anilityPurpleColor: '#6300E4'
}

const formLabelFontSize = '1.1rem'

export const AnilityTheme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: {
      main: Colors.mainColor,
      dark: Colors.avatarColor

    },
    secondary: {
      main: Colors.anilityPurpleColor
    },
    background: {
      default: Colors.backgroundColor
    },
    text: {
      primary: Colors.primaryText,
      secondary: Colors.secondaryText
    },
    // @deprecated: please use `text.secondary` instead
    // commented out due to compilation error
    // greyText: {
    //   main: Colors.secondaryText
    // },
    success: {
      main: '#15B79E',
      contrastText: green[900]
    },
    error: {
      main: '#F04438',
      contrastText: red[900]
    },
    subscriptionExpired: {
      main: '#F04438',
      contrastText: '#FFFFFF'
    },
    subscriptionSubscription: {
      main: '#1A67FF',
      contrastText: '#FFFFFF'
    },
    subscriptionTrial: {
      main: '#F79009',
      contrastText: '#FFFFFF'
    },
    subscriptionPayg: {
      main: '#06AED4',
      contrastText: '#FFFFFF'
    }
  },
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 'bold'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: Colors.mainColor,
          textDecoration: 'underline',
          cursor: 'pointer'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '3rem',
          minWidth: '9.375rem',
          textTransform: 'none',
          borderRadius: 0,
          fontWeight: 500,
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          boxSizing: 'border-box'
        },
        containedSizeSmall: {
          height: '2rem',
          minWidth: '5rem',
          fontSize: '0.8rem'
        },
        containedSizeMedium: {
          fontSize: '0.9rem'
        },
        containedPrimary: {
          ':hover': {
            backgroundColor: Colors.hoverColor,
            boxShadow: 'none',
            opacity: 0.9
          },
          ':focus': {
            boxShadow: 'none'
          },
          color: 'white',
          fontSize: '1rem',
          ':disabled': {
          },
          boxShadow: 'none'
        },
        outlinedPrimary: {
          ':focus': {
            boxShadow: 'none'
          },
          fontSize: '1rem',
          ':disabled': {
          },
          boxShadow: 'none'
        },
        containedSecondary: {
          ':hover': {
            backgroundColor: Colors.backgroundColor,
            boxShadow: 'none',
            color: Colors.mainColor
          },
          ':focus': {
            boxShadow: 'none'
          },
          backgroundColor: 'inherit',
          color: Colors.mainColor,
          fontSize: '1rem',
          border: '1px solid',
          borderColor: Colors.mainColor,
          ':disabled': {
            color: Colors.secondaryColor,
            backgroundColor: Colors.backgroundColor,
            border: 'none'
          },
          boxShadow: 'none'
        },
        outlinedSecondary: {
          color: 'black',
          fontSize: '1rem',
          border: '0.063rem solid rgba(0, 0, 0, 0.2)',
          ':hover': {
            backgroundColor: Colors.backgroundColor,
            boxShadow: 'none',
            border: '0.063rem solid rgba(0, 0, 0, 0.8)'
          }
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          boxShadow: 'none',
          ':hover': {
            backgroundColor: Colors.hoverColor,
            opacity: 0.9
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 1,
          '.MuiInputLabel-root.MuiInputLabel-shrink ': {
            fontWeight: 500,
            fontSize: formLabelFontSize,
            color: Colors.secondaryText,
            backgroundColor: 'inherit',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale'
          },
          '.MuiOutlinedInput-root.Mui-error': {
            '& fieldset': {
              borderColor: Colors.closeIconColor
            }
          },
          '.MuiFormHelperText-root.Mui-error': {
            color: Colors.closeIconColor
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          overflow: 'unset'
        }
      },
      variants: [
        {
          // commented out due to compilation error
          // props: { variant: 'inner' },
          props: { variant: 'elevation' },
          style: {
            color: 'white',
            backgroundColor: Colors.mainColor
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            border: 0
          }
        }
      ]
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '18px'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          width: 24,
          height: 24
        },
        root: {
          svg: {
            width: '1.125rem',
            height: '1.125rem'
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          paddingTop: 11
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderColor: Colors.mainColor,
          borderRadius: 0,
          textTransform: 'uppercase',
          fontWeight: 700,
          fontSize: '0.625rem'
        },
        deleteIcon: {
          color: 'black',
          fontSize: '0.875rem'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '2.5rem',
          height: '2.5rem'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          'fieldset > legend > span': {
            fontSize: `calc(${formLabelFontSize} * 0.75)`
          },
          '&.MuiInputBase-root': {
            borderRadius: 0
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            padding: '0.9rem',
            '&.MuiInputBase-inputSizeSmall': {
              padding: '0.65rem'
            }
          }
        }

      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          tr: {
            th: {
              backgroundColor: Colors.tableHeaderBackground,
              color: Colors.tableHeaderText,
              fontWeight: 600
            }
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.white
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          '.navbar li > .MuiListItemText-root > span': {
            fontWeight: 600
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          paddingTop: '10px',
          paddingBottom: '10px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: 'white'
        },
        primary: {
          fontSize: '0.875rem',
          fontWeight: 600,
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '0.5rem'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 0,
          textAlign: 'left'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    }
  },
  typography: {
    fontFamily: '"Open Sauce One", sans-serif',
    fontWeightRegular: 300,
    h1: {
      fontWeight: 500,
      fontSize: '2.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.775rem'
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.375rem'
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.125rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '0.875rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '0.625rem'
    },
    body1: {
      fontWeight: 300,
      fontSize: '0.875rem'
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.75rem'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '0.8125rem'
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.75rem'
    },
    label: {
      fontWeight: 400,
      fontSize: '0.875rem',
      display: 'block'
    },
    paragraph: {
      color: Colors.paragraphColor,
      marginTop: '1rem',
      display: 'block',
      fontWeight: 400
    },
    link: {
      fontWeight: 600,
      textDecoration: 'underline !important'
    }
  }
})

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 5px 5px 0px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))
