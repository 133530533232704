import { ChangeEvent, useState, useEffect } from 'react'
import {
  Box,
  Card,
  CardContent as MUICardContent,
  Grid,
  Typography,
  styled,
  IconButton,
  Button,
  Alert,
  TextField
} from '@mui/material'
import { Check, DeleteOutlined } from '@mui/icons-material'
import { CustomerDetails, getCustomerDetails, resetLoadingStatus } from '../get-customer-details-slice'
import { formatDate } from '../../../../utils/functions'
import { SimpleUploadButton } from '../../../../components/simple-upload-button'
import { DownloadLink } from '../../../../components/download-link'
import { Colors } from '../../../../theme/anility-dark-theme'
import { useAppSelector, useAppDispatch } from '../../../../hooks/hooks'
import { CustomerDetailsChip } from './customer-details-chip'
import { resetUpdateCustomerIdentifier, updateCustomerIdentifier } from '../update-customer-identifier-slice'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

interface CustomerDetailsHeaderProps
{
  customerDetails?: CustomerDetails
  writeCustomer: boolean
  onUploadSignedOutreachLetter: (e: ChangeEvent) => void
  onDeleteSignedOutreachLetter: () => void
  onSendSSOInvites: () => void
  onSpecialRequirementsButtonClick?: () => void
}

const CardContent = styled(MUICardContent)({
  padding: '1rem',
  '&:last-child': {
    paddingBottom: '0.75rem'
  }
})

export const CustomerDetailsHeader = ({
  customerDetails,
  writeCustomer,
  onUploadSignedOutreachLetter,
  onDeleteSignedOutreachLetter,
  onSendSSOInvites,
  onSpecialRequirementsButtonClick
}: CustomerDetailsHeaderProps) => {
  const [isEditingCustomerIdentifier, setIsEditingCustomerIdentifier] = useState(false)
  const [customerIdentifier, setCustomerIdentifier] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useAppDispatch()
  const {
    sendCustomerSSOInitialBatchInvitesState,
    updateCustomerIdentifierState
  } = useAppSelector((state) => state)

  const displaySSOContainer = writeCustomer && (customerDetails?.canSendSSOInvites ||
    sendCustomerSSOInitialBatchInvitesState.error || sendCustomerSSOInitialBatchInvitesState.result)

  const handleCancelUpdateCustomerIdentifier = () => {
    setIsEditingCustomerIdentifier(false)
    setCustomerIdentifier(customerDetails?.customerIdentifier ?? '')
    setErrorMessage('')
    dispatch(resetUpdateCustomerIdentifier())
  }

  const saveChanges = () => {
    if (customerIdentifier.trim().length === 0) {
      setCustomerIdentifier('')
      setErrorMessage('Company Identifier is required.')
      return
    }

    const customerId = customerDetails?.id
    if (customerId) {
      dispatch(updateCustomerIdentifier({
        customerId,
        customerIdentifier,
        onSuccess: () => {
          dispatch(
            getCustomerDetails({
              customerId
            })
          )
        }
      }))
    }
    setIsEditingCustomerIdentifier(false)
    setErrorMessage('')
  }

  useEffect(() => {
    setCustomerIdentifier(customerDetails?.customerIdentifier ?? '')
    setErrorMessage(updateCustomerIdentifierState.error)
  }, [customerDetails?.customerIdentifier, updateCustomerIdentifierState.error])

  useEffect(() => {
    dispatch(resetLoadingStatus())
    dispatch(resetUpdateCustomerIdentifier())
  }, [])

  return (
    <Card>
      <CardContent>
        <Box>
          <Grid container rowGap={1}>
            <Grid item xs={12} display="inline-flex">
              <Typography variant="h3">{customerDetails?.name}</Typography>
              <CustomerDetailsChip customerDetails={customerDetails} />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>Company Identifier:</Typography>
                {isEditingCustomerIdentifier
                  ? <>
                      <TextField
                          type="text"
                          variant="standard"
                          size="small"
                          value={customerIdentifier}
                          error={Boolean(errorMessage)}
                          inputProps={{ maxLength: 255 }}
                          onChange={(e) => setCustomerIdentifier(e.target.value)} />
                      <IconButton onClick={handleCancelUpdateCustomerIdentifier}><CloseOutlinedIcon fontSize="small" color="action" /></IconButton>
                      <IconButton onClick={saveChanges}><CheckOutlinedIcon fontSize="small" color="action" /></IconButton>
                    </>
                  : <>
                      <Typography variant="body1" color={(theme) => theme.palette.text.secondary} ml={0.5}>{customerDetails?.customerIdentifier}</Typography>
                      <IconButton onClick={() => setIsEditingCustomerIdentifier(true)}><EditOutlinedIcon fontSize="small" color="action" /></IconButton>
                    </>}
              </Box>
            </Grid>
            {errorMessage && (
              <Grid item xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>Joined On: {formatDate(customerDetails?.joinedDate, 'MMMM, YYYY')}</Typography>
            </Grid>
            <Grid container alignItems={'center'} columnGap={2}>
              <Grid item alignItems="center">
              {customerDetails?.signedOutreachLetterPath
                ? <Box display="flex" alignItems="center" sx={{ mt: 0.25, mb: 0.25 }}>
                    <Check fontSize='small' style={{ color: '#00A664' }} />
                    <DownloadLink url={`${process.env.REACT_APP_API_BASE}/ui/admin/customers/${customerDetails.id}/download-signed-outreach-letter`}>Signed Engagement Letter Provided</DownloadLink>
                    {writeCustomer && <IconButton
                      aria-label="delete"
                      size="small"
                      sx={{ color: Colors.primaryText, ml: 2 }}
                      onClick={onDeleteSignedOutreachLetter}
                      title='Delete Signed Engagement Letter'
                  >
                    <DeleteOutlined />
                  </IconButton>}
                  </Box>
                : writeCustomer && (<SimpleUploadButton
                id="upload-button-file"
                acceptedFiles="application/pdf"
                uploadText='Upload Signed Engagement Letter'
                onUploadFile={onUploadSignedOutreachLetter}
                />)
              }
              </Grid>
              <Grid item>
                <Button variant='contained' onClick={onSpecialRequirementsButtonClick}>Special Requirements</Button>
              </Grid>
            </Grid>
            {displaySSOContainer && <Grid item container xs={12}>
              {customerDetails?.canSendSSOInvites && (
                <Button variant='contained' color='primary' onClick={onSendSSOInvites}>Send SSO Invites</Button>
              )}
              {!!sendCustomerSSOInitialBatchInvitesState.error && (
                <Alert severity="error" sx={{ ml: 1, flex: 1 }}>{sendCustomerSSOInitialBatchInvitesState.error}</Alert>
              )}
              {sendCustomerSSOInitialBatchInvitesState.result && (
                <Alert
                  severity={sendCustomerSSOInitialBatchInvitesState.result.success ? 'success' : 'warning'}
                  sx={{ ml: sendCustomerSSOInitialBatchInvitesState.result.success ? 0 : 1, flex: 1 }}>{`${sendCustomerSSOInitialBatchInvitesState.result.successCount} out of ${sendCustomerSSOInitialBatchInvitesState.result.totalCount} invitation has been sent to users.`}</Alert>
              )}
            </Grid>}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}
