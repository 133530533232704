import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Drawer,
  Grid,
  MenuList,
  Theme
} from '@mui/material'
import { AppLogo } from './app-logo'
import { MenuListItem, MenuListItemProps } from './menu-list-item'

import './sidebar.scss'
import { useAppSelector } from '../hooks/hooks'

interface SidebarProps {
  hideMenu?: boolean;
  drawerWidth: number;
  open: boolean;
  menuItems?: MenuListItemProps[]
  onClose?: () => void;
};

const EndMenu = () => {
  const { appSettingsState } = useAppSelector((state) => state)
  const { isAuthenticated } = useAuth0()
  return !isAuthenticated
    ? (
      <></>
      )
    : (
      <Grid
        item
        container
        wrap="nowrap"
        flexDirection="column-reverse"
        justifySelf="stretch"
        flexGrow="1"
        flex="1"
        typography="body2"
        pb={2}
      >
        <MenuList>
        <Box textAlign="center">{`${appSettingsState.settings?.currentEnvironment} - ${appSettingsState.settings?.productVersion}`}</Box>
        <Box textAlign="center">{`Alares - ${appSettingsState.settings?.alaresEnabled ? 'enabled' : 'disabled'}`}</Box>
        </MenuList>
      </Grid>
      )
}

export const Sidebar = ({ open, onClose, drawerWidth, hideMenu, menuItems }: React.PropsWithChildren<SidebarProps>) => {
  return (
    <Drawer
      variant="permanent"
      open={open}
      anchor="left"
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'relative',
          boxSizing: 'border-box',
          width: open ? drawerWidth : 0,
          zIndex: 0
        }
      }}
    >
      {open && (<Grid
        className="_sidebar"
        container
        direction="column"
        justifyContent="flex-start"
        display="flex"
        pl={4}
        pr={4}
        sx={{
          height: '100%',
          backgroundColor: (theme: Theme) => theme.palette.primary.main
        }}
      >
        <AppLogo />
        <Grid item pb={2} pt={3}></Grid>
        {!hideMenu && (
          <>
            <MenuList>
              {menuItems?.map(m =>
                m.show && <MenuListItem key={m.title} {...m} />
              )}
            </MenuList>
            <EndMenu />
          </>
        )}
      </Grid>)}
    </Drawer>
  )
}
