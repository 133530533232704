import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { LoadingType } from '../../../utils/types'
import { AppDispatch, RootState } from '../../../store/store'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface RefreshIndustryInsights {
  onSuccess?: () => void
  onError?: (error: RejectValue) => void
}

interface RefreshIndustryInsightsState {
  loading: LoadingType
  error: string
}

const initialState: RefreshIndustryInsightsState = {
  loading: 'idle',
  error: ''
}

export const refreshIndustryInsights = createAsyncThunk<
  void,
  RefreshIndustryInsights,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('jobs/refresh-industry-insights', async (request, thunkAPI) => {
  const { tokenState, appSettingsState } = thunkAPI.getState()
  try {
    await axios.post<{}>(
      `${process.env.REACT_APP_API_BASE}/ui/admin/jobs/refresh-industry-insights`,
      request,
      {
        headers: {
          Authorization: `Bearer ${tokenState.token}`,
          'Content-Type': 'application/json'
        },
        validateStatus: (status) => status < 400
      }
    )

    request.onSuccess?.()
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    let errorMsg = getGenericErrorMessage(appSettingsState)
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      errorMsg = errors.response.data.title
    }
    const errorDetail = {
      statusCode: errors.response?.status,
      message: errorMsg
    }
    request.onError?.(errorDetail)

    return thunkAPI.rejectWithValue(errorDetail)
  }
})

export const refreshIndustryInsightsSlice = createSlice({
  name: 'refresh-industry-insights',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(refreshIndustryInsights.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(refreshIndustryInsights.fulfilled, (state) => {
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(refreshIndustryInsights.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.error.message || ''
    })
  }
})
