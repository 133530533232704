import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'

import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { getGenericErrorMessage } from '../../../utils/functions'
import { RejectValue } from '../../../interfaces/reject-value'

interface ApproveCustomerUserAsEntityAssessedUserState {
  loading: LoadingType;
  error: string;
}

const initialState: ApproveCustomerUserAsEntityAssessedUserState = {
  loading: 'idle',
  error: ''
}

interface ApproveCustomerUserAsEntityAssessedUserParams {
  entityAssessedUserId: number;
  entityAssessedId: number;
  onSuccess?: () => void
}

export const approveCustomerUserAsEntityAssessedUser = createAsyncThunk<
  void,
  ApproveCustomerUserAsEntityAssessedUserParams,
  {
    dispatch: AppDispatch
    state: RootState,
    rejectValue: RejectValue
  }
>(
  'entityAssessed/approveCustomerUserAsEntityAssessedEntityAssessedUser',
  async (request: ApproveCustomerUserAsEntityAssessedUserParams, thunkAPI) => {
    const { tokenState, appSettingsState } = thunkAPI.getState()

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE}/ui/admin/entityAssessed/${request.entityAssessedId}/approve-customer-as-entity-assessed/${request.entityAssessedUserId}`, request, {
        validateStatus: (status) => status < 400,
        headers: { Authorization: `Bearer ${tokenState.token}` }
      })
      request.onSuccess?.()
    } catch (error) {
      const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
      if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
        return thunkAPI.rejectWithValue({ message: errors.response.data.title })
      }

      return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
    }
  }
)

export const approveCustomerUserAsEntityAssessedUserSlice = createSlice({
  name: 'approve-customer-as-entity-assessed',
  initialState,
  reducers: {
    resetApproveCustomerUserAsEntityAssessedUser: (state) => {
      state.error = ''
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(approveCustomerUserAsEntityAssessedUser.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(approveCustomerUserAsEntityAssessedUser.fulfilled, (state) => {
      state.loading = 'loaded'
    })
    builder.addCase(approveCustomerUserAsEntityAssessedUser.rejected, (state, action) => {
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export default approveCustomerUserAsEntityAssessedUserSlice.reducer
export const { resetApproveCustomerUserAsEntityAssessedUser } = approveCustomerUserAsEntityAssessedUserSlice.actions
