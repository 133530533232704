import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContentCopy, PersonAddAlt1, Send, Circle, RadioButtonChecked, Approval } from '@mui/icons-material'
import { Alert, Box, Button, Grid, Tooltip } from '@mui/material'
import parse from 'html-react-parser'

import { formatDate } from '../../../utils/functions'
import { usePermission } from '../../../hooks/use-permission'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { EntityAssessedDetails } from './get-entity-assessed-details-slice'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { ActionMenu, ContextMenuItemProps, createContextMenuItem } from '../../../components/action-menu'
import { EntityAssessedUser, getEntityAssessedUsers, setPageNumber, setPageSize } from './get-entity-assessed-users-slice'
import { resendInviteEntityAssessedUser, resetResendInviteEntityAssessedUser } from './resend-invite-entity-assessed-user-slice'
import { reactivateEntityAssessedUser, resetReactivateEntityAssessedUser } from './reactivate-entity-assessed-user-slice'
import { resetSelectedEntityAssessed, setSelectedEntityAssessed } from './set-selected-entity-assessed-slice'
import { approveCustomerUserAsEntityAssessedUser } from './approve-customer-as-entity-assessed-slice'

interface EntityAssessedUsersProps {
  open: boolean;
  entityAssessedDetails: EntityAssessedDetails
  onClose: () => void;
  onClickInviteTeamMemberButton?: () => void;
}

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='flex-end'>
    <TablePagination page={page}
      onTablePageChange={onTablePageChange}
      onTablePageSizeChange={onTablePageSizeChange} />
  </Grid>

const columns: Column[] = [{
  id: 'tempFirstName',
  label: 'First Name',
  minWidth: 100
}, {
  id: 'tempLastName',
  label: 'Last Name',
  minWidth: 100

}, {
  id: 'email',
  label: 'Email',
  minWidth: 100
},
{
  id: 'phoneNumber',
  label: 'Phone Number',
  minWidth: 100
},
{
  id: 'status',
  label: 'Status',
  minWidth: 120,
  format: (row) => {
    const isActive = row.isSelected && !row.isDeleted
    return <Grid container alignItems="center">
      <Tooltip title={isActive ? 'This entity is currently selected for this user.' : ''}>
        <Circle fontSize="small" color={isActive ? 'success' : 'disabled'} />
      </Tooltip>
      {row.isDeleted ? 'Deactivated' : row.status}
    </Grid>
  }
},
{
  id: 'lastLoginDateTime',
  label: 'Last Login Date',
  minWidth: 100,
  format: (row) => <>{formatDate(row.lastLoginDateTime ?? '', 'YYYY/MM/DD HH:mm:ss')}</>
}]

const EntityAssessedUsers = ({
  open,
  entityAssessedDetails,
  onClose,
  onClickInviteTeamMemberButton
}: EntityAssessedUsersProps) => {
  const { getEntityAssessedUsersState, resendInviteEntityAssessedUserState, reactivateEntityAssessedUserState, setSelectedEntityAssessedState } = useAppSelector(state => state)
  const { items, totalCount, pageNumber, pageSize } = getEntityAssessedUsersState
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [writeInviteTeamMember] = usePermission([AnilityScopes.Write.InviteTeamMember])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = getEntityAssessedUsersState.loading === 'loading' || resendInviteEntityAssessedUserState.loading === 'loading' ||
    reactivateEntityAssessedUserState.loading === 'loading' || setSelectedEntityAssessedState.loading === 'loading'
  const error = getEntityAssessedUsersState.error || resendInviteEntityAssessedUserState.error || reactivateEntityAssessedUserState.error || setSelectedEntityAssessedState.error

  useEffect(() => {
    if (!entityAssessedDetails || isNaN(entityAssessedDetails.id)) {
      navigate('..')
    } else {
      dispatch(getEntityAssessedUsers({
        entityAssessedId: entityAssessedDetails.id
      }))
    }
  }, [entityAssessedDetails, pageSize, pageNumber])

  useEffect(() => {
    dispatch(resetResendInviteEntityAssessedUser())
    dispatch(resetReactivateEntityAssessedUser())
    dispatch(resetSelectedEntityAssessed())
  }, [])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }
  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleResendInvite = (item: EntityAssessedUser) => {
    setSuccessMessage('')
    dispatch(resendInviteEntityAssessedUser({
      entityAssessedId: entityAssessedDetails.id,
      entityAssessedUserId: item.id,
      onSuccess: () => {
        setSuccessMessage(`Resend invite for ${item.email} is successful.`)
      }
    }))
  }

  const handleApproveInvite = (item: EntityAssessedUser) => {
    setSuccessMessage('')
    dispatch(approveCustomerUserAsEntityAssessedUser({
      entityAssessedUserId: item.id,
      entityAssessedId: entityAssessedDetails.id,
      onSuccess: () => {
        setSuccessMessage(`Approve invite for ${item.email} is successful. The user needs to logout and login again and will see in user profile menu a switch between customer and entity assessed`)
        dispatch(getEntityAssessedUsers({
          entityAssessedId: entityAssessedDetails.id
        }))
      }
    }))
  }

  const handleCopyRegistrationLink = (item: EntityAssessedUser) => {
    setSuccessMessage('')
    navigator.clipboard.writeText(item.registrationLink)
    setSuccessMessage(`Link for email ${item.email} is successfully copied.`)
  }

  const handleReactivateUser = (item: EntityAssessedUser) => {
    setSuccessMessage('')
    dispatch(reactivateEntityAssessedUser({
      entityAssessedId: entityAssessedDetails.id,
      entityAssessedUserId: item.id,
      onSuccess: (response) => {
        const message = response.status === 'Pending'
          ? `Pending user with email <a href="mailto:${item.email}">${item.email}</a> is reactivated. A new invite email has been sent.`
          : `User with email <a href="mailto:${item.email}">${item.email}</a> is reactivated. User has been notified via email.`
        setSuccessMessage(message)
        dispatch(getEntityAssessedUsers({
          entityAssessedId: entityAssessedDetails.id
        }))
      }
    }))
  }

  const handleSetSelectedEntityAssessed = (item: EntityAssessedUser) => {
    setSuccessMessage('')
    dispatch(setSelectedEntityAssessed({
      entityAssessedId: entityAssessedDetails.id,
      entityAssessedUserId: item.id,
      onSuccess: () => {
        setSuccessMessage(`${entityAssessedDetails.entityName} is selected and the default entity for this user.<br/>Please advise them to logout and login again.`)
        dispatch(getEntityAssessedUsers({
          entityAssessedId: entityAssessedDetails.id
        }))
      }
    }))
  }

  const generateContextMenu = useCallback((item: EntityAssessedUser): MenuListItemProps[] => {
    return [
      ApproveInviteMenuItem({
        show: item.status === 'Pending' && writeInviteTeamMember && item.hasCustomerUser,
        onClick: () => handleApproveInvite(item)
      }),
      ResendInviteMenuItem({
        show: item.status === 'Pending' && writeInviteTeamMember,
        onClick: () => handleResendInvite(item)
      }),
      CopyInviteLink({
        show: item.status === 'Pending' && writeInviteTeamMember,
        onClick: () => item.registrationLink && handleCopyRegistrationLink(item)
      }),
      ReactivateUserMenuItem({
        show: item.isDeleted && writeInviteTeamMember,
        onClick: () => handleReactivateUser(item)
      }),
      SelectEntityMenuItem({
        show: item.hasSelectableEntity && item.status === 'Joined' && writeInviteTeamMember,
        onClick: () => handleSetSelectedEntityAssessed(item)
      })
    ]
  }, [writeInviteTeamMember])

  const page = { totalCount, size: pageSize, number: pageNumber }
  return (
    <>
      <CustomDialogForm
        handleSubmit={() => { }}
        maxWidth={1100}
        disablePrimary={true}
        onClose={() => { onClose() }}
        primaryText=''
        title='Team Members'
        open={open}
        showCloseDialogIcon={true}
        applyFixedWidth={true}
        hideDialogActions={true}
      >
        <Grid container pb={1} pt={1}>
          <PaginatedTable
            columns={columns}
            items={items}
            headerComponent={
              <Box display="flex" sx={{ minWidth: '100%', height: 'auto', justifyContent: error || successMessage ? 'space-between' : 'flex-end', alignItems: 'center' }}>
                {successMessage
                  ? <Alert severity='success' sx={{ textAlign: 'left', marginRight: 1, alignItems: 'center' }}>{parse(successMessage)}</Alert>
                  : error && <Alert severity='error' sx={{ textAlign: 'left', marginRight: 1, alignItems: 'center' }}>{error}</Alert>}
                {!!onClickInviteTeamMemberButton && <Button variant="contained" onClick={onClickInviteTeamMemberButton}>Invite team member</Button>}
              </Box>
            }
            footerComponent={
              <PaginationComponent
                page={page}
                onTablePageChange={handlePageChange}
                onTablePageSizeChange={handlePageSizeChange}
              />
            }
            renderMenuRowActions={(item, index, itemLength) => (
              <ActionMenu
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)}
                index={index}
                itemLength={itemLength}
              />
            )}
          />
        </Grid>
      </CustomDialogForm>
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default EntityAssessedUsers

export const ApproveInviteMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Approve customer user as entity-assessed user', <Approval />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const ResendInviteMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Resend Invite', <Send />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const CopyInviteLink = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Copy Link', <ContentCopy />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const ReactivateUserMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Reactivate', <PersonAddAlt1 />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const SelectEntityMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Set current entity as selected', <RadioButtonChecked />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}
