import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { Customer, getCustomers, setPageNumber, setPageSize, setSearchText } from './get-customers-slice'
import PaginatedTable, { Column } from '../../../components/paginated-table'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { TableHeader } from '../../../components/table-header'
import { secureFileDownload } from '../../../utils/functions'
import { formatCustomerName } from '../../../components/customer-name-component'

const columns: Column[] = [{
  id: 'name',
  label: 'Customer Name',
  minWidth: 400,
  align: 'left',
  format: (row) => formatCustomerName(row, 'name', 'subscriptionPlan')
},
{
  id: 'status',
  label: 'Status',
  width: 100,
  align: 'right'
},
{
  id: 'subscriptionPlan',
  label: 'Subscription Plan',
  width: 150,
  align: 'right',
  format: (row) => row.subscriptionPlan === 'Payg' ? 'PAYG' : row.subscriptionPlan
}
]

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <>
    <Grid container>
      <Grid item xs={8} py={2}>
      </Grid>
      <Grid item xs={4}>
        <TablePagination page={page}
          onTablePageChange={onTablePageChange}
          onTablePageSizeChange={onTablePageSizeChange} />
      </Grid>
    </Grid>
  </>

const CustomerList = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { getCustomersState, tokenState } = useAppSelector((state) => state)
  const { items, pageSize, pageNumber, totalCount, searchText } = getCustomersState
  const loading = getCustomersState.loading === 'loading'

  const [localSearch, setLocalSearch] = useState(searchText)

  useEffect(() => {
    if (getCustomersState.loading === 'idle') {
      dispatch(getCustomers())
    }
  }, [getCustomersState.loading])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }

  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(event.target.value)
  }

  const handleResetSearch = () => {
    setLocalSearch('')
    dispatch(setSearchText(''))
  }

  const handleSearch = () => {
    dispatch(setSearchText(localSearch))
  }

  const handleViewCustomer = (
    customer: Customer
  ) => {
    navigate(`${customer.id}`)
  }

  const page = { totalCount, size: pageSize, number: pageNumber }

  const handleExportButtonClick = () => {
    const params = new URLSearchParams()
    params.append('search', searchText)

    secureFileDownload(`${process.env.REACT_APP_API_BASE}/ui/admin/customers/export?${params.toString()}`, tokenState.token)
  }

  return (
    <>
      <Box p={2} sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <PaginatedTable
          columns={columns}
          items={items}
          headerComponent={<TableHeader
            title="Customers"
            description="List of Anility customers"
            searchValue={localSearch}
            onSearchTextChange={handleSearchTextChange}
            onSearchClick={handleSearch}
            onResetSearchClick={handleResetSearch}
            onExportButtonClick={handleExportButtonClick} />}
          footerComponent={
            <PaginationComponent
              page={page}
              onTablePageChange={handlePageChange}
              onTablePageSizeChange={handlePageSizeChange}
            />}
          onRowClick={handleViewCustomer}
        />
      </Box >
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default CustomerList
