import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { AppDispatch, RootState } from '../../../store/store'
import { LoadingType } from '../../../utils/types'
import { BaseAssessmentRequestItem } from '../../interfaces/base-assessment-request-item'
import { RejectValue } from '../../../interfaces/reject-value'
import { getGenericErrorMessage } from '../../../utils/functions'

export interface GetReportWorkspaceListState {
  items: IReportWorkspaceListOptions[]
  pageSize: number
  pageNumber: number
  totalPages: number
  totalCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
  showDeleted: boolean
  searchText: string
  loading: LoadingType
  showAssignedToCurrentUser: boolean
  showUnassigned: boolean
  error: string
}

export interface ReportWorkspaceRequest {
  pageNumber: number;
  pageSize: number;
  showDeleted: boolean;
  searchText: string;
  showUnassigned: boolean;
  showAssignedToCurrentUser: boolean
}
export interface ReportWorkspaceList {
  items: IReportWorkspaceListOptions[]
  pageNumber: number
  totalPages: number
  totalCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export interface IReportWorkspaceListOptions extends Omit<BaseAssessmentRequestItem, 'id'> {
  id: string
  assignedTo: string
  targetCompanyName: string
  inProgressModelLink: string
  reportUrl: string
  status: 'Initializing' | 'In Progress' | 'Done' | 'Error'
  createdDate: string
  isDeleted: boolean
  timeElapsedSinceOrdering: number
  entityAssessedResponseTime: number
  assessmentRequestId: number | null,
  customerSubscriptionPlan?: string
}

export const DEFAULT_PAGE_SIZE = 10

const initialState: GetReportWorkspaceListState = {
  items: [],
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  totalPages: 0,
  totalCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
  showDeleted: false,
  searchText: '',
  showUnassigned: false,
  showAssignedToCurrentUser: false,
  loading: 'idle',
  error: ''
}

interface GetReportWorkspacesParams {
  customerId?: string
  entityAssessedId?: number
}

export const getReportWorkspaces = createAsyncThunk<
  ReportWorkspaceList,
  GetReportWorkspacesParams,
  {
    dispatch: AppDispatch
    state: RootState
    rejectValue: RejectValue
  }
>('report/getReportWorkspaces', async (request, thunkAPI) => {
  const { tokenState, getReportWorkspacesState, appSettingsState } = thunkAPI.getState()
  const { pageNumber, pageSize, searchText, showDeleted, showUnassigned, showAssignedToCurrentUser } = getReportWorkspacesState

  const params = new URLSearchParams()
  params.append('pageNumber', pageNumber.toString())
  params.append('pageSize', pageSize.toString())
  params.append('search', searchText)

  if (request.customerId || request.entityAssessedId) {
    params.append('customerId', request.customerId ?? '')
    params.append('entityAssessedId', request.entityAssessedId?.toString() ?? '')
    params.append('showDeleted', 'false')
  } else {
    params.append('showUnassigned', showUnassigned.toString())
    params.append('showAssignedToCurrentUser', showAssignedToCurrentUser.toString())
    params.append('showDeleted', showDeleted.toString())
  }

  try {
    const response = await axios.get<ReportWorkspaceList>(
      `${process.env.REACT_APP_API_BASE
      }/ui/admin/reportworkspace?${params.toString()}`,
      {
        headers: { Authorization: `Bearer ${tokenState.token}` },
        validateStatus: (status) => status < 400
      }
    )
    return response.data
  } catch (error) {
    const errors: AxiosError<{ title: string, detail: string, status: number }> = error as any
    if (axios.isAxiosError(errors) && errors.response?.status !== 500 && errors.response?.data) {
      return thunkAPI.rejectWithValue({ message: errors.response.data.title })
    }

    return thunkAPI.rejectWithValue({ message: getGenericErrorMessage(appSettingsState) })
  }
})

export const getReportWorkspacesSlice = createSlice({
  name: 'get-report-workspaces-list',
  initialState,
  reducers: {
    showDeleted: (state, action: PayloadAction<boolean>) => {
      state.showDeleted = action.payload
      state.loading = 'idle'
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.pageNumber = 1
      state.loading = 'idle'
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
      state.loading = 'idle'
    },
    resetLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.loading = 'idle'
      state.error = ''
      if (action.payload) {
        state.pageSize = initialState.pageSize
        state.pageNumber = initialState.pageNumber
        state.searchText = initialState.searchText
        state.items = initialState.items
      }
    },
    setShowUnassigned: (state, action: PayloadAction<boolean>) => {
      state.showUnassigned = action.payload
      state.loading = 'idle'
    },
    setShowAssignedToCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.showAssignedToCurrentUser = action.payload
      state.loading = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getReportWorkspaces.pending, (state) => {
      state.loading = 'loading'
    })
    builder.addCase(getReportWorkspaces.fulfilled, (state, { payload }) => {
      state.items = payload.items
      state.pageNumber = payload.pageNumber
      state.totalPages = payload.totalPages
      state.totalCount = payload.totalCount
      state.hasNextPage = payload.hasNextPage
      state.hasPreviousPage = payload.hasPreviousPage
      state.loading = 'loaded'
      state.error = ''
    })
    builder.addCase(getReportWorkspaces.rejected, (state, action) => {
      state.items = []
      state.loading = 'error'
      state.error = action.payload?.message || ''
    })
  }
})

export const { showDeleted, setSearchText, setPageSize, setPageNumber, resetLoadingStatus, setShowUnassigned, setShowAssignedToCurrentUser } = getReportWorkspacesSlice.actions
export default getReportWorkspacesSlice.reducer
