import { ChangeEvent, MouseEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Grid, IconButton } from '@mui/material'
import { ContentCopy, Download, PersonAddAlt1, Send } from '@mui/icons-material'
import parse from 'html-react-parser'

import { formatDate } from '../../../utils/functions'
import { AnilityScopes } from '../../../interfaces/anility-scopes'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import { MenuListItemProps } from '../../../components/menu-list-item'
import { CustomDialogForm } from '../../../components/custom-dialog-form'
import { Column, PaginatedTable } from '../../../components/paginated-table'
import { TablePagination, TablePaginationProps } from '../../../components/table-pagination'
import { CustomerUser, getCustomerUsersList, setPageNumber, setPageSize } from './get-customer-users-slice'
import { ActionMenu, ContextMenuItemProps, createContextMenuItem } from '../../../components/action-menu'
import { resendInviteCustomerUser, resetResendInviteCustomerUser } from './resend-invite-customer-user-slice'
import { usePermission } from '../../../hooks/use-permission'
import { reactivateCustomerUser, resetReactivateCustomerUser } from './reactivate-customer-user-slice'

interface CustomerUsersListProps {
  open: boolean;
  id: number
  handleClose: () => void;
  onClickInviteTeamMemberButton?: () => void;
  onExportButtonClick?: () => void;
}

const PaginationComponent = ({ page, onTablePageChange, onTablePageSizeChange }: TablePaginationProps) =>
  <Grid container justifyContent='flex-end'>
    <TablePagination page={page}
      onTablePageChange={onTablePageChange}
      onTablePageSizeChange={onTablePageSizeChange} />
  </Grid>

const CustomerUsersList = ({
  handleClose,
  open,
  id,
  onClickInviteTeamMemberButton,
  onExportButtonClick
}: CustomerUsersListProps) => {
  const { getCustomerUsersState, getCustomerDetailsState, resendInviteCustomerUserState, reactivateCustomerUserState } = useAppSelector(state => state)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [writeInviteTeamMember] = usePermission([AnilityScopes.Write.InviteTeamMember])
  const { items, totalCount, pageNumber, pageSize } = getCustomerUsersState
  const loading = getCustomerUsersState.loading === 'loading' || resendInviteCustomerUserState.loading === 'loading' || reactivateCustomerUserState.loading === 'loading' || getCustomerDetailsState.loading === 'loading'
  const error = getCustomerUsersState.error || resendInviteCustomerUserState.error || reactivateCustomerUserState.error
  const isSsoEnabled = getCustomerDetailsState.customerDetails?.isSSOEnabled

  const columns: Column[] = [{
    id: 'tempFirstName',
    label: 'First Name',
    minWidth: 100
  }, {
    id: 'tempLastName',
    label: 'Last Name',
    minWidth: 100

  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    minWidth: 100
  },
  {
    id: 'role',
    label: 'Role'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'lastLoginDateTime',
    label: 'Last Login Date',
    minWidth: 100
  }]

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      dispatch(getCustomerUsersList({
        customerId: id
      }))
    }
  }, [id, pageSize, pageNumber])

  useEffect(() => {
    dispatch(resetResendInviteCustomerUser())
    dispatch(resetReactivateCustomerUser())
  }, [])

  const handlePageChange = (_event: MouseEvent<HTMLButtonElement> | null, selectedPage: number) => {
    dispatch(setPageNumber(selectedPage + 1))
  }
  const handlePageSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPageSize(+event.target.value))
  }

  const handleResendInvite = (item: CustomerUser) => {
    setSuccessMessage('')
    dispatch(resendInviteCustomerUser({
      customerUserId: item.id,
      onSuccess: () => {
        setSuccessMessage(`Resend invite for ${item.email} is successful.`)
      }
    }))
  }

  const handleCopyLink = (item: CustomerUser) => {
    setSuccessMessage('')
    const link = isSsoEnabled ? item.ssoInvitationLink : item.registrationLink
    const subject = isSsoEnabled ? 'SSO Invite' : 'Registration'
    navigator.clipboard.writeText(link)
    setSuccessMessage(`${subject} Link for email ${item.email} is successfully copied.`)
  }

  const handleReactivateUser = (item: CustomerUser) => {
    setSuccessMessage('')
    dispatch(reactivateCustomerUser({
      customerUserId: item.id,
      onSuccess: (response) => {
        const message = response.status === 'Pending'
          ? `Pending user with email <a href="mailto:${item.email}">${item.email}</a> is reactivated. A new invite email has been sent.`
          : `User with email <a href="mailto:${item.email}">${item.email}</a> is reactivated. User has been notified via email.`
        setSuccessMessage(message)
        dispatch(getCustomerUsersList({
          customerId: id
        }))
      }
    }))
  }

  const generateContextMenu = useCallback((item: CustomerUser): MenuListItemProps[] => {
    const showInviteMenuItem = isSsoEnabled ? !!item.ssoInvitationLink : writeInviteTeamMember && item.status === 'Pending'
    return [
      ResendInviteMenuItem({
        show: showInviteMenuItem,
        onClick: () => handleResendInvite(item)
      }),
      CopyInviteLink({
        show: showInviteMenuItem,
        onClick: () => item.registrationLink && handleCopyLink(item)
      }),
      ReactivateUserMenuItem({
        show: item.isDeleted && writeInviteTeamMember,
        onClick: () => handleReactivateUser(item),
        disabled: isSsoEnabled
      })
    ]
  }, [writeInviteTeamMember])

  const formattedItems = items.map((item) => { return { ...item, lastLoginDateTime: formatDate(item.lastLoginDateTime ?? '', 'YYYY/MM/DD HH:mm:ss') } })
  const page = { totalCount, size: pageSize, number: pageNumber }

  return (
    <>
      <CustomDialogForm
        handleSubmit={() => { }}
        maxWidth={1100}
        disablePrimary={true}
        onClose={() => {
          handleClose()
        }}
        primaryText=''
        title='Team Members'
        open={open}
        showCloseDialogIcon={true}
        applyFixedWidth={true}
        hideDialogActions={true}
      >
        <Grid container pb={1} pt={3}>
          <PaginatedTable
            columns={columns}
            items={formattedItems}
            headerComponent={
              <Box display="flex" sx={{ minWidth: '100%', height: 'auto', justifyContent: error || successMessage ? 'space-between' : 'flex-end', alignItems: 'center' }}>
                <Grid container sx={{ flexWrap: 'nowrap', justifyContent: 'flex-end', gap: '16px', alignItems: 'center' }}>
                  <Grid item flexGrow={1}>
                    { successMessage ? <Alert severity='success' sx={{ width: '100%' }}>{parse(successMessage)}</Alert> : error && <Alert severity='error' sx={{ width: '100%' }}>{error}</Alert> }
                    { (!successMessage && !error && isSsoEnabled) && <Alert severity='info' sx={{ width: '100%' }}>SSO is enabled for this customer. Customer can reactivate via their active directory.</Alert>}
                  </Grid>
                  { !!onExportButtonClick &&
                    <Grid item>
                      <IconButton sx={{ height: '2.125rem', borderRadius: 0, backgroundColor: (theme) => theme.palette.grey[300] }} color="primary" onClick={onExportButtonClick}><Download /></IconButton>
                    </Grid>
                  }
                  { !!onClickInviteTeamMemberButton &&
                    <Grid item>
                      <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} onClick={onClickInviteTeamMemberButton}>Invite team member</Button>
                    </Grid>
                  }
                </Grid>
              </Box>
            }
            footerComponent={
              <PaginationComponent
                page={page}
                onTablePageChange={handlePageChange}
                onTablePageSizeChange={handlePageSizeChange}
              />
            }
            renderMenuRowActions={(item, index, itemLength) => (
              <ActionMenu
                contextMenuIndicatorMarginLeft={0.5}
                menuListItems={generateContextMenu(item)}
                index={index}
                itemLength={itemLength}
              />
            )}
          />
        </Grid>
      </CustomDialogForm>
      <AnilityBackdrop open={loading} />
    </>
  )
}

export default CustomerUsersList

export const ResendInviteMenuItem = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Resend Invite', <Send />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const CopyInviteLink = ({ onClick, show }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Copy Link', <ContentCopy />, [AnilityScopes.Write.InviteTeamMember], onClick, show)
}

export const ReactivateUserMenuItem = ({ onClick, show, disabled }: ContextMenuItemProps): MenuListItemProps => {
  return createContextMenuItem('Reactivate', <PersonAddAlt1 />, [AnilityScopes.Write.InviteTeamMember], disabled ? () => {} : onClick, show, disabled)
}
