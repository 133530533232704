import { Alert, Box, Grid, Typography } from '@mui/material'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { CustomDialogForm } from '../../../../components/custom-dialog-form'
import { CustomFile, UploadFile } from '../../../../components/upload-file'
import { Colors } from '../../../../theme/anility-dark-theme'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { resetUploadReportWorkspaceFileAttachment, uploadReportWorkspaceFileAttachment } from '../upload-report-workspace-file-attachment-slice'
import { AnilityBackdrop } from '../../../../components/anility-backdrop'
import { useCallback, useEffect, useState } from 'react'
import { getReportWorkspaceFileAttachments } from '../get-report-workspace-file-attachments-slice'
import { deleteReportWorkspaceFileAttachment, resetDeleteReportWorkspaceFileAttachment } from '../delete-report-workspace-file-attachment-slice'
import { FileRejection } from 'react-dropzone'

export interface ReportWorkspaceAttachFilesDialogProps {
  reportWorkspaceId: string
  open: boolean
  assessmentLocked: boolean
  onClose?: () => void
}

export const ReportWorkspaceAttachFilesDialog = ({ reportWorkspaceId, open, assessmentLocked, onClose }: ReportWorkspaceAttachFilesDialogProps) => {
  const dispatch = useAppDispatch()
  const {
    uploadReportWorkspaceFileAttachmentState, getReportWorkspaceFileAttachmentsState,
    deleteReportWorkspaceFileAttachmentState
  } = useAppSelector(state => state)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [preUploadError, setPreUploadError] = useState<string>('')
  const loading = uploadReportWorkspaceFileAttachmentState.loading === 'loading' || getReportWorkspaceFileAttachmentsState.loading === 'loading' || deleteReportWorkspaceFileAttachmentState.loading === 'loading'
  const error = uploadReportWorkspaceFileAttachmentState.error || getReportWorkspaceFileAttachmentsState.error || deleteReportWorkspaceFileAttachmentState.error
  const fileAttachments = getReportWorkspaceFileAttachmentsState?.items?.map((_) => ({
    fileName: _.fileName,
    fileSize: _.fileSize,
    downloadUrl: _.downloadUrl
  }))

  const noop = () => { }

  const handleClose = () => {
    setSuccessMessage('')
    setPreUploadError('')
    dispatch(resetUploadReportWorkspaceFileAttachment())
    dispatch(resetDeleteReportWorkspaceFileAttachment())
    onClose?.()
  }

  const handleFileDropAccepted = (acceptedFiles: File[]) => {
    if (acceptedFiles?.length > 0 && !!reportWorkspaceId) {
      dispatch(uploadReportWorkspaceFileAttachment({
        reportWorkspaceId,
        files: acceptedFiles,
        onSuccess: () => {
          setSuccessMessage('File(s) successfully uploaded.')
          dispatch(getReportWorkspaceFileAttachments({
            reportWorkspaceId
          }))
        }
      }))
    }
  }

  const handleFileRejected = (rejectedFiles: FileRejection[]) => {
    setSuccessMessage('')
    const errorFiles: string[] = []
    rejectedFiles.forEach((rejection) => {
      const { file, errors } = rejection
      errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          errorFiles.push(file.name)
        }
      })
    })
    setPreUploadError(errorFiles.length > 0 ? `${errorFiles.join(', ')} has not been uploaded successfully. The file(s) is larger than 15MB.` : '')
  }

  const handleFileRemove = useCallback((file: CustomFile) => {
    setPreUploadError('')
    if (file.fileName && reportWorkspaceId) {
      dispatch(deleteReportWorkspaceFileAttachment({
        reportWorkspaceId,
        fileName: file.fileName,
        onSuccess: () => {
          setSuccessMessage(`${file.fileName} was successfully removed.`)
          dispatch(getReportWorkspaceFileAttachments({
            reportWorkspaceId
          }))
        }
      }))
    }
  }, [reportWorkspaceId])

  useEffect(() => {
    if (!reportWorkspaceId || !open) return

    setPreUploadError('')
    dispatch(resetUploadReportWorkspaceFileAttachment())
    dispatch(getReportWorkspaceFileAttachments({
      reportWorkspaceId
    }))
  }, [reportWorkspaceId, open])

  return (
    <CustomDialogForm
      open={open}
      onClose={handleClose}
      title="Attach files to workspace"
      minWidth={900}
      applyFixedWidth
      hideDialogActions
      showCloseDialogIcon>
      <Grid item xs={12}>
        {preUploadError && preUploadError.trim() !== '' && (
            <Alert severity="error" sx={{ mb: 1 }}>
              {preUploadError}
            </Alert>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 1 }}>{error}</Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mb: 1 }}>{successMessage}</Alert>
        )}
      </Grid>
      <Grid container item xs justifyContent="flex-start" flexDirection="column">
        <UploadFile
          maxFileSize={15 * 1024 * 1024}
          acceptedFileTypes={{
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'image/*': []
          }}
          disabled={assessmentLocked}
          title={
            <Box typography="h4" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <CloudUploadOutlinedIcon sx={{ fontSize: 28, fontWeight: 400, m: 0.5 }} />
              <Typography
                variant="h4"
                sx={(theme) => ({ color: !assessmentLocked ? theme.palette.primary.main : Colors.secondaryColor })}
              >
                Browse file
              </Typography>
              <Typography
                variant="h5"
                sx={(theme) => ({ color: !assessmentLocked ? theme.palette.text.secondary : Colors.secondaryColor, m: 0.5 })}
              >
                Drag and drop files here
              </Typography>
            </Box>
          }
          uploadedFiles={fileAttachments}
          onFileDropAccepted={handleFileDropAccepted}
          onFileAccept={noop}
          onFileReject={handleFileRejected}
          onFileRemove={handleFileRemove}
        />
      </Grid>
      <AnilityBackdrop open={loading} />
    </CustomDialogForm>
  )
}
